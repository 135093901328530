/* Shadow */
.shadow-xl { 
  box-shadow: $box-shadow-xl;
}
.shadow-light {
  box-shadow: 0 0.25rem 0 rgba(255, 255, 255, 0.1);
  transition: all .5s;
}

.shadow-sm-hover {
  box-shadow: 0 0.25rem 0 rgba(0, 0, 0, .1);
  transition: all .5s;
  &:hover {
    box-shadow: 0 0.25rem 0 rgba(0, 0, 0,.3);
  }
}