/* Pagination */
.pagination {
  .page-item {
    &:first-child,
    &:last-child {
      .page-link {
        background-color: $danger;
        box-shadow: 0 4px 0 rgba(0, 0, 0, .1);
        border-radius: 0.375rem;
        color: $white;
        padding: 0.625rem;
        @include media-breakpoint-up(md) {
          padding: .9rem 1.5rem;
        }
        @include media-breakpoint-up(lg) {
          padding: .74rem 1.6rem;
        }
        &:hover {
          color: $white;
        }
      }
    }
    &:first-child {
      .page-link {
        margin-right: 7px;
        @include media-breakpoint-up(lg) {
          margin-right: 14px;;
       }
      }
    }
    &:last-child {
      .page-link {
        margin-left: 7px;
         @include media-breakpoint-up(lg) {
          margin-left: 14px;
       }
      }
    }
    .page-link {
      background-color: transparent;
      font-size: $pagination-font-size;
      text-transform: uppercase;
      font-weight: 600;
      padding: 7px;
      font-size: 14px;
      transition: all 0.4s;
      @include media-breakpoint-up(md) {
        padding: 0.8125rem;
        font-size: .8rem;
      }
      @include media-breakpoint-up(lg) {
        padding: 0.9375rem;
        font-size: 1rem;
      }
      &:hover {
        color: $danger;
      }
    }
  }
}