/* Progres Wizard */
.progress-wizard {
	margin-top: 2.5rem;
	margin: 2.5rem 0 4.38rem 0;
	padding: 0 0 1.88rem 0;
	> .progress-wizard-step {
		padding: 0;
		position: relative;
		height: 105px;
    margin-bottom: 3rem;

		@include media-breakpoint-up(md) {
			margin-bottom: 0;
			height: 100px;
		}
		> .progress {
			position: relative;
			border-radius: 0;
			height: 5px;
			box-shadow: none;
			margin: 1.25rem 0;
			> .progress-bar {
				width: 0;
				box-shadow: none;
        background-color: transparent;
				@include media-breakpoint-up(md) {
          background: $danger;
        }

			}
		}
		&.complete {
			> .progress {
				> .progress-bar {
					width:100%;
				}
			}
		}
		&.active {
			> .progress {
				> .progress-bar {
					width:50%;
				}
			}
		}
	}
}
.progress-wizard {
	> .progress-wizard-step {
		> .progress {
			> .progress-bar {
					width:0%;
			}
		}
	}
}
.progress-wizard {
	> .progress-wizard-step:first-child.active {
		> .progress {
			> .progress-bar {width:0%;}
		}
	}
}
.progress-wizard {
	> .progress-wizard-step:last-child.active {
		> .progress {
			> .progress-bar {
				width: 100%;
			}
		}
	}
}

.progress-wizard {
	> .progress-wizard-step:first-child  {
		> .progress {left: 50%; width: 50%;}
	}
}
.progress-wizard {
	> .progress-wizard-step:last-child  {
		> .progress {
			width: 50%;
		}
	}
}
.progress-wizard {
	> .progress-wizard-step.disabled {
		a.progress-wizard-dot {
			pointer-events: none;
		}
	}
}
.progress{
  background-color: transparent;
	margin-bottom: 10px;
  @include media-breakpoint-up(md) {
    background-color: #f8f8f8;
  }
}
.progress-wizard {
	> .progress-wizard-step {
		> .progress-wizard-dot {
			display: block;
			background-color: $white;
      border-radius: 100%;
      border: 5px solid $danger;
			padding: 5px;
			text-align: center;
			color: #222;
      width: 110px;
      position: relative;
      top: 0;
      left: 0;
      margin: -1.25rem auto 0 auto;

			@include media-breakpoint-up(md){
				width: auto;
				position: absolute;
				top: -1.88rem;
				left: 28%;
				margin-top: -0.94;
				margin-left: -0.94;
			}
			i {
				display: block;
				color: $danger;
				font-size: 1.5rem;
				line-height: 90px;
				width: 90px;
				height: 90px;
        border-radius: 100%;
				background-color: #ededed;
				text-align: center;
				@include media-breakpoint-up(md){
					line-height: 102px;
					width: 102px;
					height: 102px;
				}
			}
			&:hover {
        opacity: 1;
			}
		}
	}
}
.progress-wizard {
	> .progress-wizard-step.incomplete {
		> .progress-wizard-dot {
			border-color: #f8f8f8;
			color: $text-color;
			i {
				color: $text-color;
			}
		}
	}
}
.progress-wizard {
	> .progress-wizard-step.complete {
		> .progress-wizard-dot{
			i {
				color: $white;
				background-color: $danger;
			}
		}
	}
}
.progress-wizard {
	.progress-info {
		text-align: center;
		font-family: 'Dosis', sans-serif;
		font-weight: 600;
		font-size: 1rem;
		color: #222222;
		@include media-breakpoint-up(md) {
			margin: 4.38rem 0 0 0.9375rem;
		}
		@include media-breakpoint-up(lg) {
			margin: 4.38rem 0 0 -0.9375rem;
		}
		@include media-breakpoint-up(xl) {
			margin: 4.38rem 0 0 -2.5rem;
		}
	}
}
