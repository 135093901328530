/* From */

.form-control {
  border-radius: .625rem;
  font-size: .875rem;
  &::placeholder {
    color: $text-color;
    opacity: .7;
  }
  &:active,
  &:focus {
    box-shadow: none;
    border-color: #ced4da;
  }
}

.form-group-icon {
  position: relative;

  i {
    position: absolute;
    right: 15px;
    top: 15px;
    color: #888;
    font-size: .81rem;
  }
}

.input-addon {
  .form-control {
    background-color: #f5f5f5;
    border-radius: 0.25rem;
    border: 0;
  }
  .input-group-append {
    .input-group-text {
      border: 0;
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }
  }
}

.input-group {
  .form-control {
    &:focus {
      z-index: 0;
    }
  }
}

.input-group-addon {
  &:hover {
    box-shadow: none !important;
  }
  &:focus {
    box-shadow: none !important;
  }
}

/* Search Result  */
.input-group-lg {
  .input-group-prepend {
    height: 55px !important;
    @include media-breakpoint-up(md){
      height: 70px !important;
    }
  }
  .input-group-text {
    height: 55px !important;
    @include media-breakpoint-up(md){
      height: 70px !important;
    }
  }
  .input-group-append {
    @include media-breakpoint-up(md) {
      width: 20%;
    }
    .btn-lg {
      width: 100%;
      font-size: .88rem;
      padding: 6px 12px;
      height: 55px !important;
      @include media-breakpoint-up(md){
        height: 70px !important;
        font-size: 1.13rem;
      }
    }
  }
  .form-control-lg {
    font-size: 0.8125rem;
    height: 55px !important;
    @include media-breakpoint-up(md){
      height: 70px !important;
      font-size: 0.9375rem;
    }
  }
}
