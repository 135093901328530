/* Border Width Custom */
.border-1 {
  border: 1px solid $border-color;
}
.border-2 {
  border: 2px solid $border-color;
}
.border-4 {
  border: 4px solid $border-color;
}
.border-5 {
  border: 5px solid $border-color;
}
.border-top-5 {
  border-top: 5px solid $border-color;
}

/* Round Custom */
.rounded-lg {
  border-radius: 0.8rem;
}
.rounded {
  border-radius: $border-radius * 2 !important;
}
.rounded-top {
  border-top-left-radius: $border-radius * 2 !important;
  border-top-right-radius: $border-radius * 2 !important;
}
.rounded-right {
  border-top-right-radius: $border-radius * 2 !important;
  border-bottom-right-radius: $border-radius * 2 !important;
}
.rounded-bottom {
  border-bottom-right-radius: $border-radius * 2 !important;
  border-bottom-left-radius: $border-radius * 2 !important;
}
.rounded-left {
  border-top-left-radius: $border-radius * 2 !important;
  border-bottom-left-radius: $border-radius * 2 !important;
}

.rounded-sm {
  border-radius: $border-radius-sm !important;
}
.rounded-top-sm {
  border-top-left-radius: $border-radius-sm !important;
  border-top-right-radius: $border-radius-sm !important;
}
.rounded-bottom-sm {
  border-bottom-left-radius: $border-radius-sm !important;
  border-bottom-right-radius: $border-radius-sm !important;
}
