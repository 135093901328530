/* Buttons Customize */
.btn {
  box-shadow: 0 .25rem 0 rgba(0, 0, 0, .1);
  font-size: $btn-font-size;
  &:hover {
    box-shadow: 0 4px 0 rgba(0,0,0,.3)!important;
  }
  &:focus {
    box-shadow: 0 4px 0 rgba(0,0,0,.3)!important;
  }
}

.btn.btn-link {
  box-shadow: none!important;
}

.btn-white {
  background-color: $white;
  color: $text-color;
}

.btn-link {
  color: $text-color;
  font-size: 0.875rem;
  font-weight: $font-weight-medium;
  transition: all 0.4s;
  &:hover {
    text-decoration: none;
  }
}
.btn-squre {
  border: 2px solid $white;
  padding: .375rem .625rem;
  i {
    color: $white;
    font-size: .94rem;
  }
}

.btn-scroll-down {
  cursor: pointer;
  visibility: hidden;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  i {
    font-size: 1.13rem;
    color: $white;
  }
}

.btn-quickview {
  border-radius: 0;
  padding: 0;
  background-color: $danger;
  display: block;
  width: 100%;
  color: $white;
  text-transform: uppercase;
  font-size: 13px;
  height: 55px;
  line-height: 55px;
  position: absolute;
  bottom: 0;
  visibility: hidden;
  opacity: 0;
  transition: transform .3s ease-in-out;
  transform: translateY(20px);
  &:hover,
  &:focus {
    box-shadow: none!important;
  }
}

.btn-sm {
  font-size: .875rem;
  border-radius: .25rem;
}

.btn-lg {
  font-size: 1.125rem;
}

/* Buttons Of Fillter */
.button-group {
  text-align: center;
  margin-bottom: 14px;
  .button {
    padding: 0 1.56rem;
    height: 2.5rem;
    font-size: .875rem;
    line-height: 2.5rem;
    color: $dark;
    text-transform: uppercase;
    font-weight: 600;
    display: inline-block;
    box-shadow: 0 .25rem 0 rgba(0,0,0,.1);
    background-color: #f8f8f8;
    border-radius: .25rem;
    border: 0;
    margin-right: .625rem;
    margin-bottom: 1rem;
    cursor: pointer;
    &:focus{
      outline: 0;
    }
    &.is-checked {
      background-color: $danger;
      color: $white;
      transition: all 0.4s;
    }
  }
}

// btn-hover
.btn-hover-warning {
  &:hover {
    background-color: $warning;
    color: $white;
  }
  &:focus {
    background-color: $warning;
    color: $white;
    box-shadow: 0 .25rem 0 rgba($warning, .1);

  }
}

.btn-danger {
  color: $white;
}

.btn-hover-danger {
  &:hover {
    background-color: $danger;
    color: $white;
  }
  &:focus {
    background-color: $danger;
    color: $white;
    box-shadow: 0 .25rem 0 rgba($danger, .1);

  }
}
.btn-hover-success {
  &:hover {
    background-color: $success;
    color: $white;
  }
  &:focus {
    background-color: $success;
    color: $white;
    box-shadow: 0 .25rem 0 rgba($success, .1);

  }
}
.btn-hover-info {
  &:hover {
    background-color: $info;
    color: $white;
  }
  &:focus {
    background-color: $info;
    color: $white;
    box-shadow: 0 .25rem 0 rgba($info, .1);

  }
}
.btn-hover-purple {
  &:hover {
    background-color: $purple;
    color: $white;
  }
  &:focus {
    background-color: $purple;
    color: $white;
    box-shadow: 0 .25rem 0 rgba($purple, .1);

  }
}
.btn-hover-pink {
  &:hover {
    background-color: $pink;
    color: $white;
  }
  &:focus {
    background-color: $pink;
    color: $white;
    box-shadow: 0 .25rem 0 rgba($pink, .1);

  }
}

.input-group-prepend,
.input-group-append {
  cursor: pointer;
}

.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
  outline: 0;
}