/* Media */
.media {
  // align-items: center;
  .media-body {
    h3 {
      font-family: $font-family-dosis;
      font-weight: 700;
      text-transform: capitalize;
      font-size: 1.5rem;
    }
  }
}

/* Media Image Overlay */
.media-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

/* Media Hoverable*/
.media-hoverable {
  overflow: hidden;
  border-radius: 0.25rem;
  margin-bottom: 1.56rem;
  .media-img {
    transition: transform .8s ease-in-out;
  }
  .media-img-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,.5);
    visibility: hidden;
    opacity: 0;
    transition: all .3s ease-in-out;
  }

  &:hover {
    .media-img {
      transform: scale(1.2);
    }
    .media-img-overlay {
      visibility: visible;
      opacity: 1;
    }
  }
}

/* Media List View */
.media-list-view {
  flex-direction: column;
  box-shadow: 0 0.25rem 0 rgba(0, 0, 0, 0.1);
  border-radius: 0.63rem;
  background-color: #f8f8f8;
  .media-img {
    position: relative;
    width: 100%;
    @include media-breakpoint-up(md) {
      width: 40%;
    }
    @include media-breakpoint-up(xl) {
      width: 33%;
    }
    img {
      max-width: 100%;
      object-fit: cover;
      width: 100%;
      border-top-left-radius: 0.63rem;
      border-top-right-radius: 0.63rem;

      @include media-breakpoint-up(md) {
        height: 260px;
        border-top-right-radius: 0;
        border-bottom-left-radius: 0.63rem;
      }
      @include media-breakpoint-up(lg) {
        height: 255px;
      }
      @include media-breakpoint-up(xl) {
        height: 233px;
      }

    }
    .media-img-overlay {
      padding: 1.25rem;
    }
  }
  @include media-breakpoint-up(md) {
    flex-direction: row;
    align-items: center;
  }
  @include media-breakpoint-up(lg) {

  }
  .media-body {
    padding: 1.25rem;
    @include media-breakpoint-up(md) {
      padding: 0 1.4rem;
    }
    h3 {
      margin-bottom: 1rem;
      a {
        font-weight: 700;
        text-transform: capitalize;
      }
    }
  }
}

/* List Fullwidth */
.list-fullwidth {
  .media-list-view {
    flex-direction: column;
    box-shadow: 0 0.25rem 0 rgba(0, 0, 0, 0.1);
    border-radius: 0.63rem;
    .media-img {
      position: relative;
      width: 100%;
      @include media-breakpoint-up(md) {
        width: 40%;
      }
      @include media-breakpoint-up(lg) {
        width: 30%;
      }
      @include media-breakpoint-up(xl) {
        width: 24%;
      }
      img {
        max-width: 100%;
        object-fit: cover;
        width: 100%;
        border-top-left-radius: 0.63rem;
        border-top-right-radius: 0.63rem;
  
        @include media-breakpoint-up(md) {
          height: 300px;
          border-top-right-radius: 0;
          border-bottom-left-radius: 0.63rem;
        }
        @include media-breakpoint-up(lg) {
          height: 255px;
        }
        @include media-breakpoint-up(xl) {
          height: 230px;
        }
  
      }
      .media-img-overlay {
        padding: 1.25rem;
      }
    }
    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
    @include media-breakpoint-up(lg) {
  
    }
    .media-body {
      padding: 1.25rem;
      @include media-breakpoint-up(md) {
        padding: 0 1.4rem;
      }
      h3 {
        margin-bottom: 1rem;
        a {
          font-weight: 700;
          text-transform: capitalize;
        }
      }
    }
  }
}

/* Media Event View */
.media-events-list {
  flex-direction: column;
  box-shadow: 0 0.25rem 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 1.875rem;
  overflow: hidden;
  border-radius: 0.8125rem;
  
  @include media-breakpoint-up(lg) {
    flex-direction: row;
    align-items: center;
  }
  
  .media-img {
    position: relative;
    width: 100%;
    @include media-breakpoint-up(md) {
      // width: 35%;
    }
    @include media-breakpoint-up(lg) {
      // width: 50%;
    }
    @include media-breakpoint-up(xl) {
      width: 46%;
    }
    img {
      max-width: 100%;
      object-fit: cover;
      width: 100%;
      @include media-breakpoint-up(md){
        height: auto;
      }
      @include media-breakpoint-up(lg){
        height: 260px;
      }
      @include media-breakpoint-up(xl){
        height: 280px;
        box-shadow: 4px 0 0 rgba(0,0,0,.1);
      }
    }
  }
  .media-body {
    padding: 1.25rem;
    @include media-breakpoint-up(md){}
    @include media-breakpoint-up(lg){
      width: 50%;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 1.25rem; 
    }
    @include media-breakpoint-up(xl){
      padding-left: 2rem; 
    }
  }
}

/* Cart Media */
.cart-dropdown {
  .media {
    .media-body {
      h4 {
        font-weight: bold;
      }
      .price {
        span {
          font-size: 1.7rem;
        }
      }
    }
  }
}

/* Pricing List */
.pricing-list {
  flex-direction: column;
  box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  @include media-breakpoint-up(md) {
    flex-direction: row;
    align-items: center;
  }
  .pricing-plan {
    width: 100%;
    padding: 2.1875rem 1.25rem 5rem;
    border-radius: 8px 8px 0 0;
    @include media-breakpoint-up(md) {
      width: 50%;
      padding: 2.1875rem 1.25rem 1.125rem;
      border-radius: 8px 0px 0 8px;
    }
    h3 {
      font-size: 2.13rem;
      font-family: "Dosis", sans-serif;
      font-weight: 600;
    }
    .pricing-price {
      font-size: 2.13rem;
      font-family: "Dosis", sans-serif;
      font-weight: 600;
      line-height: 28px;
      small {
        font-size: .875rem;
      }
    } 
  }
  .media-body {
    width: 100%;
    padding: 1.25rem;
    @include media-breakpoint-up(md) {
      width: 50%;
      padding: 0 0 0 1.25rem;
    }
  }
}

/* Media Avator View */
.media-avator-view {
  .media-img {
    width: 100%;
    img {
      width: 100%;
    }
    @include media-breakpoint-up(md) {
      width: 33%;
    }
    @include media-breakpoint-up(lg) {
      width: 26%;
    }
    @include media-breakpoint-up(lg) {
      width: 22%;
    }
  }
  .media-body {
    a {
      color: $dark;
      transition: all .4s;
      &:hover {
        color: $danger;
      }
    }
  }
}