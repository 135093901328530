// Font
@import url('https://fonts.googleapis.com/css?family=Dosis:300,400,600,700|Open+Sans:300,400,600,700');

// Kidz Scss
@import 'kidz';

@import 'animation';

// Custom
@import 'custom';
