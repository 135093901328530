// .fa-spinner {
//     animation: 1s rotate .1s infinite alternate;
//     font-size: 2rem !important;
// }
.fa-spinner.normal {
    font-size: 1rem !important;
}

@keyframes rotate  {
    from {
        transform: rotate(0deg);
    } to {
        transform: rotate(360deg);
    }
}
