/* Card */

.card {
  box-shadow: $box-shadow;
  position: relative;
  margin-bottom: 1.56rem;
  .card-header-lg {
    h3 {
      font-size: 2.15rem;
      font-family: $font-family-dosis;
      font-weight: 600;
    }
  }
  .card-body {
    .card-title {
      font-size: 1.5rem;
      font-family: $font-family-dosis;
      font-weight: 700;
    }
    .pricing-price {
      font-size: 2.13rem;
      font-family: $font-family-dosis;
      font-weight: 600;
      line-height: 28px;
      small {
        font-size: .875rem;
        color: $text-color;
      }
    }
    big {
      font-size: 1.25rem;
      color: #fff;
      text-transform: capitalize;
      margin-bottom: .625rem;
      font-weight: 600;
      @include media-breakpoint-up(xl) {
        font-size: 1.69rem;
      }
    }
  }
}

/* Card Image Wrapper */

.card-img-wrapper {
  width: 150px;
  height: 150px;
  .card-img-overlay {
    background-color: rgba(0, 0, 0, .45);
    visibility: hidden;
    opacity: 0;
    transition: all .3s ease-in-out;
  }
}

/* Card Border */

.card-border {
  border: 4px solid;
  .card-body {
    h5 {
      font-size: 1.125rem;
      font-family: $font-family-dosis;
      font-weight: 700;
    }
    span {
      font-weight: 700;
      font-size: 1.4375rem;
    }
  }
}

/* Card Hover */

.card-hover {
  &:hover {
    .card-img-wrapper {
      .card-img-overlay {
        visibility: visible;
        opacity: 1;
      }
    }
    .btn-scroll-down {
      visibility: visible;
      opacity: 1;
    }
    .btn-quickview {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
      z-index: 10;
    }
  }
}

/* Card Transparent */

.card-transparent {
  background-color: transparent !important;
  .card-body {
    a {
      color: $white;
      transition: all 0.4s;
    }
  }
  &:hover {
    .card-body {
      a {
        color: $warning;
      }
    }
  }
}

/* Accordion Error */

.accordion-error {

}

.card-product {
  border: 4px solid;
  box-shadow: 0 0.25rem 0 rgba(0, 0, 0, 0.1);
  background-color: $white;
  .img-link {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 200px;
    img {
      max-height: 130px;
    }
  }
  .card-body {
    h5 {
      a {
        font-size: 1.25rem;
        font-weight: 700;
        color: $white;
        display: block;
      }
    }
  }
}
.card-product-fill-bg {
  padding: 1.25rem;
  .card-link {
    display: flex;
    flex-direction: column;
    height: 230px;
    text-align: center;
    .img-link {
      max-width: 100%;
      max-height: 200px;
      padding: 2rem;
    }
    span {
      margin-top: auto;
      color: $white;
      font-weight: bold;
      font-size: 1.25rem;
      font-family: $font-family-secondary;
    }
  }
}


// Category Slider 
.categories-slider {
  @include media-breakpoint-up(xl){
    height: 600px;
  }
  .card {
    .card-img-top {
      border-radius: 0.25rem;
      @include media-breakpoint-up(xl){
        max-height: 595px;
      }
    }
  }
}