/* Opacity With background */
.opacity-70 {
  opacity: .7;
}

.opacity-75 {
  opacity: .75;
}

.opacity-80 {
  opacity: .80;
}

.opacity-85 {
  opacity: .85;
}