p {
  font-size: .875rem;
  color: $text-color;
}

a {
  &:hover {
    text-decoration: none;
  }
}

/* Text Decoration  */
.text-underline {
  text-decoration: underline!important;
  &:hover {
    text-decoration: underline!important;
  }
}

/* Text Color */
.text-color {
  color: $text-color;
}

/* Font Family */
.font-dosis {
  font-family: $font-family-dosis;
}
.font-inherit {
  font-family: $font-family-inherit;
}
.font-base {
  font-family: $font-family-base;
}

/* Font Size */
.font-size-12 {
  font-size: .75rem;
}
.font-size-13 {
  font-size: .81rem;
}
.font-size-14 {
  font-size: .875rem;
}
.font-size-15 {
  font-size: .94rem;
}
.font-size-16 {
  font-size: 1rem;
}
.font-size-17 {
  font-size: 1.1rem;
}
.font-size-18 {
  font-size: 1.13rem;
}
.font-size-19 {
  font-size: 1.19rem;
}
.font-size-20 {
  font-size: 1.25rem!important;
}
.font-size-24 {
  font-size: 1.5rem;
}
.font-size-28 {
  font-size: 1.75rem;
}
.font-size-32 {
  font-size: 2rem;
}
.font-size-40 {
  font-size: 2.5rem;
}
.font-size-48 {
  font-size: 3rem;
}
.font-size-55 {
  font-size: 3.4375rem!important;
}

/* Font Weight */
.font-weight-medium {
  font-weight: $font-weight-medium;
}

/* Text Hover */
.text-hover-warning {
  &:hover {
    color: $warning;
  }
}
.text-hover-danger {
  transition: all 0.5s;
  &:hover {
    color: $danger;
  }
}
.text-hover-success {
  &:hover {
    color: $success;
  }
}
.text-hover-info {
  &:hover {
    color: $info;
  }
}
.text-hover-purple {
  &:hover {
    color: $purple;
  }
}
.text-hover-pink {
  &:hover {
    color: $pink;
  }
}
