/* Modal Small */
.modal-sm {
  .modal-content {
    border-radius: 0.625rem;
  }
}

.modal.show .modal-dialog.modal-sm {
  transform: translate(0, 50px);
}

/* Modal Large */
.modal-lg {
  .modal-header {
    padding: 0;
    .close {
      font-size: 2.5rem;
      position: absolute;
      z-index: 1;
      cursor: pointer;
      top: 25px;
      right: 40px;
    }
  }
  .modal-body {
    padding: 45px 50px;
    // @include media-breakpoint-up(md) {
    
    // }
  }
}

.modal.show .modal-dialog.modal-lg {
  transform: translate(0, 80px);
  max-width: 280px;
  margin: 0 auto;
  @include media-breakpoint-up(md) {
    max-width: 750px;
  }
  @include media-breakpoint-up(lg) {
    max-width: 950px;
  }
  @include media-breakpoint-up(xl) {
    max-width: 1030px;
  }
}