// Custom RTL
html[dir="rtl"] { 
  .navbar-nav .dropdown .dropdown-menu .dropdown-item i {
    transform: translateY(-50%) rotate(180deg);
  }
   
  .owl-nav i {
    transform: rotate(180deg);
  }
  .team-slider .card .card-img-wrapper .card-img-overlay .list-unstyled {
    padding-right: 0 !important;
  }
}
