/* Color Bar */
.color-bars {
  width: 100%;
  position: fixed;
  z-index: 9996;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.color-bar {
  height: 3px;
}
