// Counter
#counter {
  .counter-value {
    width: 145px;
    height: 145px;
    line-height: 145px;
    border-radius: 100%;
    border: 4px solid #fff;
    display: block;
    margin: 0 auto 22px;
    font-size: 2rem;  
    @include media-breakpoint-up(md) {
      font-size: 3.25rem;
    }
    @include media-breakpoint-up(lg) {}
    @include media-breakpoint-up(xl) {}
  }
  span {
    font-size: 0.8125rem;
    @include media-breakpoint-up(md) {
      font-size: 0.9375rem;
    }
  }
}
// .counter-value {
//   width: 145px;
//   height: 145px;
//   line-height: 145px;
//   border-radius: 100%;
//   border: 4px solid #fff;
//   display: block;
//   margin: 0 auto 22px;
//   font-size: 2rem;  
//   @include media-breakpoint-up(md) {}
//   @include media-breakpoint-up(lg) {}
//   @include media-breakpoint-up(xl) {}
// }