/* No UI Slider */

.price-range {
  .price-range-content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    @include media-breakpoint-up(md){
      flex-wrap: nowrap;
      justify-content: space-between;
    }

  }
  .price-text,
  .price-value {
    color: $input-placeholder-color;
    margin-right: 10px;
    @include media-breakpoint-up(md){
      margin-right: 0px;
    }
  }
  .price-value {
    min-width: 35px;
    margin-right: 10px;
    @include media-breakpoint-up(md){
      margin-right: 0px;
    }
  }
  .btn {
    margin-right: 10px;
    @include media-breakpoint-up(md){
      margin-right: 0px;
    }
  }
}

.noUi-target {
  background-color: #eee;
  box-shadow: none;
  border: 0;
}

.noUi-horizontal {
  height: 5px;
  .noUi-handle {
    width: 16px;
    height: 16px;
    line-height: 16px;
    background-color: $success;
    box-shadow: none;
    border: 0;
  }
}

.noUi-handle:after,
.noUi-handle:before {
  display: none;
}

.noUi-connect {
  background-color: $success;
}

.noUi-horizontal .noUi-origin,
.noUi-handle.noUi-handle-lower,
.noUi-handle.noUi-handle-upper {
  &:focus {
    outline: none;
  }
}

html:not([dir=rtl]) .noUi-horizontal .noUi-origin {
  right: 1px;
}
