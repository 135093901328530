html {
  min-height: 100%;
}

body {
    min-height: 100%;
}
.main-wrapper {
    min-height: 100%;

    .container-fluid {
        height: 100%;
    }
    .container-fluid > .row {
        height: 100%;
    }
}
.tooltip {
    z-index: 999999;
    opacity: 1 !important;
}

@media all and (max-width: 768px) {
    header .navbar {
        padding-top: 40px !important;
    }
}

label.required::before {
    content: "*";
    margin-right: 3px;
}
label.custom-file-label::after {
    content: 'Parcourir';
}
.font-size-10 {
  font-size: 10px !important;
}

.nav-tabs .month-errors-print {
  position: absolute;
  padding: 0px 5px;
  border-radius: 40%;
  margin-top: -48px;
  margin-left: 65px;
}

.circle-error {
  background: $danger;
  color: white;
  border: 1px $danger solid;
  border-radius: 40%;
  padding: 0px 5px;
}

.circle-success {
  background: $success;
  color: white;
  border: 1px $success solid;
  border-radius: 40%;
  padding: 0px 5px;
}
icon-error

.icon-success {
  color: $success !important;
}

.icon-error {
  color: $danger !important;
}

.btn.btn-xs {
  font-size: 1rem;
  padding: 0.25rem 0.5rem !important;
}

.fa-3x {
  font-size: 3em !important;
}

.tab-pane {
  max-height: 250px;
  overflow: auto;
}

.hide {
  display: none;
}
.toggle-check {
    opacity: 0;
    width: 0;
}
.spe-toggle .toggle.btn {
  width: 100% !important;
}
.spe-toggle .toggle .toggle-on, .spe-toggle .toggle .toggle-off {
  font-size: 12px !important;
}

// On mets display none sinon sur les checkbox on a une ombre qui s'ajoute dans le bouton
.toggle-handle {
  display: none;
}

@keyframes show {
    form {
        opacity: 0;
        width: 0;
    } to {
        opacity: 1;
        width: auto;
    }
}

.radius-link {
  display: inline-block;
  border-radius: 50%;
  padding: 15px;
  transition: all .5s;
}
.radius-link:hover {
  background-color: $inscription;
  color: white;
}

.radius-link h2 {
  white-space: nowrap;
  padding: 5px 0;
}

/*
 * Shortcut
 */
.caption-side-top {
  caption-side: top;
}

.w-60 {
    width: 60%;
}

button.font-size-17 {
    font-size: 17px !important;
}

/*
 * Alert
 * Override default style
 */
.alert {
    padding: 1rem;
    padding-bottom: 0.5rem;
}
.alert p {
    margin: 0;
}
/*
 * Sidebar
 */
.sidebar {
  padding: 0px 0 48px 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

.sidebar-sticky {
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

.sidebar .nav-link {
  font-weight: 600;
  color: #333;
}

.sidebar .nav-link:hover {
  color: #fff;
}

.sidebar .nav-link.active {
  color: #007bff;
}

.sidebar ul.nav {
  margin-bottom: 1.56rem;
}

h1.sidebar-heading {
  font-size: 1.7rem;
  font-weight: bold;
  margin: 1.56rem 0;
}

h2.sidebar-heading {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.31rem;
}

/** Cartes **/
.card .badge-large-right {
    top: 5px;
}
.card-inactive {
    opacity: 0.5;
}
.card-inactive:hover {
    opacity: 1;
}
.card-rounded-top {
    border-radius: 4px 4px 0px 0px;
    box-shadow: none;
}
@media all and (max-width: 768px) {
  .card-rounded-top {
    border-radius: 0px;
  }
}

/** Formulaires **/
/* Vich image */
.vich-image .form-group.row {
    text-align: right;
    margin-top: 10px;
}
.vich-image a img {
    max-height: 150px;
    width: auto;
}
.form-check-input {
  position: static;
  margin-left: 0;
}
label.form-control {
  overflow: auto;
}
.form-check {
  padding-left: 0;
}

/** Menu de gauche **/
.left-nav {
    width: 300px;
}
.left-nav ul li a {
    color: black;
}

@media (min-width: 992px) {
    .card .badge-large {
        font-size: 1.5em;
        padding: 0.625rem;
    }
}

@media all and (min-width: 768px) {
  .navbar .navbar-brand img {
    width: auto !important;
    max-height: 100px;
  }
}

.bg-enattente {
  background-color: #D558D9;
  color: white;
}
.bg-estabsent {
  background-color: crimson;
}
.planning-print-place-dispo {
  font-size: 12px;
}
.planning-print-place-dispo img {
  height: 21px;
  max-width: 30px;
}
.define-main-actu {
  transition: all .3s;
}
.define-main-actu:hover {
  cursor: pointer;
  color: orangered !important;
  transform: scale(1.1, 1.1);
}

td[name="celluleClicable"] {
  cursor: pointer;
}

/*
 * Navbar
 */
@media (min-width: 768px) {
  .navbar-nav .dropdown .nav-link span {
      width: auto;
  }
}

@media (min-width: 992px) {
  .navbar-nav .dropdown .nav-link span {
      width: auto;
      padding: 0 0.75rem;
  }
}

/** PLANNING */

.planning-tables {
  padding: 0;
  overflow-x: auto;

  tr:first-child , tr:nth-child(2) {
    background-color: $light;
    td:first-child {
      background-color: $light;
    }
  }

  tr:nth-child(n+3) td:first-child {
    background-color: white;
  }

  tr:nth-child(2) td:first-child {
    font-size: 1.2em;
    color: $primary;
    text-align: center;
    vertical-align: middle;
  }

  tr td:not:first-child {
    position: relative;
    z-index: 0;
  }
  tr td:first-child {
    position:sticky;
    left:0;
    top:auto;
    z-index: 200;
    font-weight: bold;
  }

  tr td.isweekend {
    padding-left: 1px;
    padding-right: 1px;
    max-width: 25px;
  }

  .bg-danger.isweekend {
    cursor: not-allowed;
  }

  tr td:not(:first-child) {
    text-align: center;
  }

  .bg-secondary {
    background: repeating-linear-gradient(45deg, #6c757d6c 31%, rgba(255, 255, 255, 0.315) 41%) !important;
    cursor: not-allowed;
  }
  @media all and (max-width: 882px) {
    tr td {
      min-width: 30px;
    }
    tr:not(.firstRowCol) td:first-child {
      padding: 5px !important;
    }
  }
}

.bg-repeat-grey {
    background: repeating-linear-gradient(45deg, #6c757d6c 31%, rgba(255, 255, 255, 0.315) 41%) !important;
}

.planning-nav, .calendar-nav {
  margin-bottom: 10px;
  display: inline-flex;
  vertical-align: middle;

  // Pour uniformiser avec le "titre de fullcalendar"
  h2 {
      font-size: 1.75em;
      margin: 0
  }
}

/* Surcharge template */
.progress-wizard {
    margin: 2.5rem 0 0 0;
    padding: 0 0 1.88rem 0;
}

/* Footer */
#pageBottom {
  width: 100%;
  opacity: 0;
  z-index: 99998;
}
.footer-fix {
  position: fixed;
  bottom: 0;
}

/* Titre de la page */
.page-title {
    text-align: center;

    h1 {
        font-weight: bold;
    }
}

/* FullCalendar */

// Weekend de fullcalendar
.fc-sat, .fc-sun {
    background: $light;
}


// Planning Container => FullCalendar + Schedule
#planning-container .btn-group button {
    padding: 2px 10px;
    font-weight: normal;
    font-size: 1rem;
}

// Surcharge .fc-center
.fc-center h2 {
    display: inline-block;
}

/* Custom */
.btn-cursor-hover:hover {
    cursor: pointer;
}

/* Panier */
.panier-container #wrapper i {
    position: relative;
}
.icon-header-relative {
    position: relative;
}
.panier-container #wrapper span {
    background-color: rgba(255, 255, 255, 1);
    border-radius: 50%;
    padding: 1px 5px;
    font-size: 12px;
    top: -35%;
    right: -50%;
    position: absolute;
    justify-content: start;
    z-index: 9996;
    line-height: normal;
}

@media all and (max-width: 768px) {
  .float-right, .float-left {
    float: none !important;
    display: block;
  }
}

// Select2
.select2-container--default .select2-selection--single .select2-selection__arrow b {
    top: 75% !important;
}

// Sans cela mon Select2 ne prenait pas la largeur maximale
#contenu_evolutif .select2-container--default {
  width: inherit !important;
}

.btn.btn-info, .btn.btn-success, .btn.btn-warning {
  color: white !important;
}

.ico-field {
  position: absolute;
  margin-top: -35px;
  right: 30px;
  transition: all .3s;
  z-index: 990;
}
.ico-field:hover {
  cursor: pointer;
  transform: scale(0.9, 0.9);
}

.form-inline.rounded-0 select {
  border-radius: 0;
}

@media all and (max-width: 768px) {
  .input-group.block-on-mobile {
    .input-group-prepend {
      width: 100%;
      border-radius: 0px !important;
      label.input-group-text {
        width: 100%;
      }
    }
    .form-control {
      border-radius: 0px !important;
    }
  }
}

/* Commandes */
.commande-target:target {
    border: solid red 2px !important;
}
#loader {
    width: 100%;
}
#loader-bar {
    height: 15px;
    width: 0;
}

#pageStructure {
    overflow: hidden;
}

// Page d'accueil
// Surcharge propriété mtn pour la version mobile (suppression de la marge)
@media all and (max-width: 576px) {
    .mtn-115 {
        margin-top: 0 !important;
    }
}

.custom-hr {
    width: 100% !important;
}

// CKEDITOR BODY
.ckeditor-content img {
    max-width: 100%;
    height: auto !important;
}

.menu-circle-wrapper {
    margin-bottom: 5%;
}
.menu-circle-wrapper .menu-link-wrapper {
    position: relative;
}
.menu-circle-wrapper .menu-link {
    display: block;
    background-color: #fff;
    border-radius: 100%;
    border: 5px solid #ea7066;
    padding: 5px;
    text-align: center;
    color: #222;
    width: 110px;
    position: relative;
    top: 0;
    left: 0;
    margin: -1.25rem auto 0 auto;
}
.menu-circle-wrapper .menu-link i {
    display: block;
    color: #ea7066;
    font-size: 1.5rem;
    line-height: 90px;
    width: 90px;
    height: 90px;
    border-radius: 100%;
    background-color: #ededed;
    text-align: center;
}
.logo {
  max-height: 70px;
}
.border-radius-none {
  border-radius: 0 !important;
}
.w-70 {
  width: 70%;
}
@media all and (max-width: 500px) {
  .font-size-sm-10 {
    font-size: 10px;
  }
  /* full calendar */
  .fc-center h2 {
    font-size: 15px !important;
  }
  .fc-right .btn {
    font-size: 0.75rem;
    padding: 0.5rem 0.75rem;
  }
}

/** DEBUG **/
.debug {
  background: #000;
  border-radius: 4px;
  padding: 5px 10px;
}
/** Switcher **/
.switcher-container {
  position: absolute;
  top: 5%;
  left: 2%;
  box-shadow: 0px 0px 5px black;
  border-radius: 4px;
  padding: 5px;
  z-index: 9999;
  background: white;
  min-width: 15%;
  text-align: center;
}
.clickable-badge {
  padding: 5px;
  transition: all .3s;
}
.clickable-badge:hover {
  cursor: pointer;
  transform: scale(0.9, 0.9);
}
/** Target Btn **/
.target-btn {
  opacity: 0.5;
}
.target-btn.active {
  opacity: 1;
}

tr:target {
  background: rgba(0, 0, 0, .1);
}
.mh-150-px {
  max-height: 150px !important;
  overflow: auto;
}
.bandeau-infos {
  position: fixed;
  bottom: 0;
  z-index: 999;
  width: 100%;
  overflow: hidden;
}
.select2-selection {
  border-radius: 0px !important;
}
/* AjaxContainer */
.ajax-container {
  border-radius: 9px;
  position: absolute;
  z-index: 2;
  background-color: white;
  top: 0%;
  margin: auto;
}
/* width */
.ajax-container ::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.ajax-container ::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.ajax-container ::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.ajax-container ::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.mh-350 {
  max-height: 350px;
  overflow: auto;
}
.new-enfant-rendered {
  border-radius: 50%;
  max-height: 100%;
  max-width: 25%;
  margin: auto;
  overflow: hidden;
}
@media all and (max-width: 992px) {
  #renderedImage {
    min-height: 140px;
  }
}
.ico-remove {
  position: absolute;
  top: 10px;
  transition: all .3s;
  opacity: 0.75;
}
.ico-remove:hover {
  cursor: pointer;
  opacity: 1;
}
.form-errors .invalid-feeback {
  display: block !important;
}
.planning-tables tr td {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.card-body-p-1 {
  padding: 1rem;
}
.nowrap {
  white-space: nowrap !important;
}
.rounded-top-right {
  border-radius: 0 5px 0 0 !important;
}

.scrolling-wrapper {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;

  .card {
    flex: 0 0 auto;
  }

}

@media all and (max-width: 992px) {
  .scrolling-wrapper {
    flex-wrap: wrap;
    justify-content: center;
  }
  .scrolling-wrapper .card {
    flex: none;
  }
}

.modal {
  padding: 0 !important;
  z-index: 1080;
  top: 15%;
  width: 70%;
  left: 15%;
}
@media all and (max-width: 1200px) {
  .modal {
    width: 100%;
    left: 0%;
  }
}

.btn-outline-family.rounded-circle {
  padding: 18px 1px !important;
  min-width: 100%;
  font-size: 15px;
  margin-bottom: 25px;
}
.select-card-activite .vsp, .select-card-activite .vsp i.fa {
  transition: all .3s;
}
.rounded-card {
  border-radius: 0.5rem 0 0 0.5rem !important;
}
.select-card-activite .text-right {
  transition: .4s all;
}

.select-card-activite:hover, .btn-target, .btn-url {
  cursor: pointer;
  -webkit-transform:scale(1.005); /* Safari et Chrome */
  -moz-transform:scale(1.005); /* Firefox */
  -ms-transform:scale(1.005); /* Internet Explorer 9 */
  -o-transform:scale(1.005); /* Opera */
  transform:scale(1.005);
}

@media all and (max-width: 1200px) {
  .btn-outline-family.rounded-circle {
    font-size: 14px;
    min-width: 125px;
    padding: 18px 1px !important;
  }
}
@media all and (max-width: 768px) {
  .btn-outline-family.rounded-circle {
    padding: 18px 1px !important;
    min-width: 110px;
    margin-bottom: 15px;
    font-size: 13px;

    i.fa {
      display: block;
    }

  }
}
.inline-flex {
  display: inline-flex !important;
}

/** GDPR COOKIE */
// Headband
.gdpr-headband {
  z-index: 9999;
  bottom: 0%;
  padding: 15px;
  text-align: right;
  border-top: 2px dotted grey;
}

// Modal
.gdprcookie {
  position: absolute;
  z-index: 9999;
  top: 10%;
  width: 70%;
  max-width: 70%;
  left: 15%;
  background-color: white;
  border: double 4px rgb(117, 117, 117);
  padding: 15px;

  .gdpr-close {
    position: absolute;
    top: 1%;
    right: 2%;
  }

  .gdprcookie-intro {
    h1 {
      font-size: 30px;
    }
  }

  .gdprcookie-types {
    .gdpr-prefs-container {
      button {
        font-size: 11px;
      }
      h2 {
        font-size: 18px;
      }
      p {
        font-size: 15px;
      }
      .gdpr-cookies-category {
        margin-left: 15px;
        border-bottom: 1px dotted grey;
        margin-bottom: 5px;

        h3 {
          font-size: 16px;
        }
        p {
          font-size: 12px;
        }
      }
    }
  }
}
.fiche-sanitaire-formType {
  .input-group.date-group {
    .input-group-text {
      padding-right: 0.8rem !important;
      padding-left: 0.8rem !important;
      font-size: 0.8rem;
    }
    .form-control {
      font-size: 0.8rem;
    }
    .form-inline.form-control {
      padding: 0 !important;
      height: auto;
    }
  }
}
// @media all and (max-width: 1646px) {
  .fiche-sanitaire-formType {
    .input-group.date-group {
      display: block;
      .input-group-prepend {
        width: 100%;
      }
      .input-group-text {
        width: 100%;
        border-radius: 0 !important;
      }
      .form-inline.rounded-0 {
        width: 100%;

        select {
          width: 33%;
        }
        select:last-child {
          width: 34%;
        }
      }
      .form-inline.form-control {
        padding: 0 !important;
        height: auto;
      }
    }
  }
// }
.wb-container {
  background-color: rgb(46, 46, 46) !important;
  color: white !important;
  font-weight: 750 !important;
  font-size: 15px !important;
  height: 100% !important;

  .danger {
    color: red !important;
  }
  .success {
    color: green !important;
  }
}
.HaAuthorizeButton {
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  background-color: #FFFFFF;
  border: 0.0625rem solid #49D38A;
  border-radius: 0.125rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0;
}
.HaAuthorizeButton:disabled {
  background-color: #E9E9F0;
  border-color: transparent;
  cursor: not-allowed;
}
.HaAuthorizeButton:not(:disabled):focus {
  box-shadow: 0 0 0 0.25rem rgba(73, 211, 138, 0.25);
  -webkit-box-shadow: 0 0 0 0.25rem rgba(73, 211, 138, 0.25);
}
.HaAuthorizeButtonLogo {
  padding: 0 0.8rem;
  width: 4rem;
}
.HaAuthorizeButtonTitle {
  background-color: #49D38A;
  color: #FFFFFF;
  font-size: 1rem;
  font-weight: 700;
  padding: 0.78125rem 1.5rem;
}
.HaAuthorizeButton:disabled .HaAuthorizeButtonTitle {
  background-color: #E9E9F0;
  color: #9A9DA8;
}
.HaAuthorizeButton:not(:disabled):hover .HaAuthorizeButtonTitle,
.HaAuthorizeButton:not(:disabled):focus .HaAuthorizeButtonTitle {
  background-color: #30c677;
}
.progress-wizard > .progress-wizard-step > .progress-wizard-dot {
  width: 90px;

  i {
    width: 70px;
    height: 70px;
    line-height: 70px;
    font-size: 1.8rem;
  }
}
.progress-wizard > .progress-wizard-step > .progress {
  margin: 0;
}
@media all and (min-width: 1500px) {
  .progress-wizard .col-sm-3.progress-wizard-step .progress-info {
    margin: 2.30rem 0 0 -3.2rem !important;
  }
}
@media all and (max-width: 768px) {
  .progress-wizard .progress-info {
    margin: 1rem 0 0 0 !important;
  }
}
.progress-wizard .progress-info {
  margin: 2.30rem 0 0 0;
}

.carousel-control-prev-icon {
  width: 30px;
  height: 30px;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ea77ad' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e") !important;
}

.carousel-control-next-icon {
  width: 30px;
  height: 30px;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ea77ad' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e") !important;
}

.point-infos {
background-color: #ea77ad !important;
}

// Carousel multi élément responsive pour afficher la liste des actu au dessus de la page d'accueil
@media (min-width: 991px) {
  .carousel-load .carousel-item div.carousel-load-size {
    display: flex !important;
    align-items: stretch !important;
  }
}

@media (max-width: 991px) {
   .carousel-load .card {
    min-height: 235px;
  }
   .carousel-load .carousel-inner .carousel-item > div {
      display: none;
  }
   .carousel-load .carousel-inner .carousel-item > div:first-child {
      display: block;
  }
  .carousel-load .card .card-img-top {
    display: none;
  }
}

 .carousel-load .carousel-inner .carousel-item.active,
 .carousel-load .carousel-inner .carousel-item-next,
 .carousel-load .carousel-inner .carousel-item-prev {
  display: flex;
}

/* display 3 */
@media (min-width: 1200px) {  
   .carousel-load .carousel-inner .carousel-item-right.active,
   .carousel-load .carousel-inner .carousel-item-next {
    transform: translateX(33.333%);
  }
  
   .carousel-load .carousel-inner .carousel-item-left.active, 
   .carousel-load .carousel-inner .carousel-item-prev {
    transform: translateX(-33.333%);
  }
}

/* display 2 */
@media (min-width: 991px) and (max-width: 1200px) {
  
   .carousel-load .carousel-inner .carousel-item-right.active,
   .carousel-load .carousel-inner .carousel-item-next {
    transform: translateX(50%);
  }
  
   .carousel-load .carousel-inner .carousel-item-left.active, 
   .carousel-load .carousel-inner .carousel-item-prev {
    transform: translateX(-50%);
  }
}

 .carousel-load .carousel-inner .carousel-item-right,
 .carousel-load .carousel-inner .carousel-item-left{ 
  transform: translateX(0);
}


 .carousel-load .card .card-body .card-text.text-body span {
  margin-bottom: 10px;
}

 .carousel-load .card .card-body .text-right {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.carousel-load .card .card-img-top {
  width: 100%;
  height: 10vw;
  object-fit: scale-down;
}
// Fin du carousel multi éléments

#fconnect-profile > a {
  background-size: 30px !important;
}

#cartes_cnaf div.card {
  cursor: pointer;
}

#btnFranceConnectHover {
  display: none;
}
#survol:hover img#btnFranceConnect {
  display: none;
}
#survol:hover img#btnFranceConnectHover {
  display: inline-block;
}

.wrapper {
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
}

.or-separator {
  display: flex;
  flex-direction: column;
  align-items: center;  
  color: black;
  font-weight: bold;
}

.vertical-line {
  border-left: 1px solid #bcbdbe;
  flex-grow: 1;
  width: 1px;
}

@media (max-width: 768px) {

  .wrapper {
    width: 100%;
    height: 60px;
  }

  .or-separator {
    flex-direction: row;
  }

  .vertical-line {
    border-top: 1px solid #bcbdbe;
    width: 150px;
  }
}

.btn-extra-small {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  font-size: 11px !important;
  font-weight: bold;
}

.loader-message {
  font-size: 24px;
  font-weight: bold;
  position: absolute;
  width: 100%;
  // height: 100%;
  z-index: 30;
}

.navbar {
  z-index: 999 !important;
}

.uploadError {
  font-weight: bold;
}

#loadingMessage
{
    position: fixed;
    width: 100%;
    justify-content: center;
    top: 50%;
    font-size: 30px;
    font-weight: bold;
    color:black;
}