/* Background Parallax */
.bg-parallax {
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

/* Background Repeat */
.bg-repeat {
  background-repeat: repeat;
}
